import api from './api';
import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

export const authorization = () => {
  const encryptedData = encryptData({
    email: process.env.REACT_APP_USER,
    password: process.env.REACT_APP_PASSWORD,
  }, SECRET_KEY);
  return api.post('/v1/crm/auth/authLogin', { data: encryptedData });
};
export const authorizationCp = (email, password) => {
  return api.post('/v1/cp/auth/login', {
    email,
    password,
  });
};
export const postClient = (params) => {
  return api.post('/v1/crm/customers/registration', params);
};

export const demoClient = (params) => {
  return api.post('/v1/crm/customers/demo', params);
};

export const postPartner = (params) => {
  return api.post('/v1/crm/customers/ib', params);
};

export const updateClient = (id, params) => {
  return api.patch('/v1/crm/customers/registrationUpdate/' + id, params);
};

export const uploadDocuments = (params) => {
  return api.post('/v1/crm/documents', params, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  );
}